body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.p-dialog-mask{
  z-index: 9999999999 !important;
}

@font-face {
  font-family: 'CustomFont';
  src: url('../assets/fonts/FrutigerLTArabic65Bold.ttf') format('truetype')
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

* {
  font-family: 'CustomFont', sans-serif;
}


